import Home from "./Home/Home";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./TermsOfUse/TermsOfUse";
import ScrollToTop from "./Components/ScrollToTop";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import GetApp from "./Components/GetApp";
import TravelGuides from "./TravelGuides/TravelGuides";
import SearchPage from "./Components/SearchPage";
import ItenaryDetail from "./Components/ItenaryDetails/ItenaryDetail";
import TravellerDetail from "./Components/TravllerDeatils/TravellerDetail";
import Footer from "./Components/Footer";
import { SearchPageDetail } from "./Components/searchpageItenary/SearchPageDetail";
import { DestinationDetail } from "./Components/Destination/DestinationDetail";
import { AllImagesPreview } from "./Components/Destination/AllImagesPreview";
import { logPageView } from "./analytics";
import React from "react";
import { ErrorPage } from "./Components/ErrorPage";


const App = () => {
  // const location = useLocation()

  React.useEffect(() => {
    logPageView();
  }, []);

  return (
    <div className="App flex flex-col h-full box-border bg-[#fafafa] overflow-x-hidden">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms-of-use" element={<TermsOfUse />} />
          <Route exact path="/get-app" element={<GetApp />} />
          <Route exact path="/travel-guides" element={<TravelGuides />} />
          <Route exact path="/search" element={<SearchPage />} />
          <Route exact path="/search-detail" element={<SearchPageDetail />} />
          <Route path="/itinerary-detail/:id" element={<ItenaryDetail />} />
          <Route path="/traveller-detail/:id" element={<TravellerDetail />} />
          <Route
            path="/destination-detail/:id/:placeID"
            element={<DestinationDetail />}
          />
          <Route
            path="/destinationDetail/gallery/:id"
            element={<AllImagesPreview />}
          />
          <Route exact path="*" element={<ErrorPage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
