import { Box, Chip, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import EyeChip from "../../Assets/EyeChip.svg";
import UserChip from "../../Assets/UserChip.svg";
import PopularTrips from "../../Assets/destinationDummyImg.jpg";
import "../../Components/components.css";

import { Helmet } from "react-helmet";
import BackIcon from "../../Assets/BackIcon.svg";
import { logPageView } from "../../analytics";
import { formatDate, getImagefromBase64, getStoredImages } from "../../helper";
import { getItineraryDetails } from "../../redux/actions/authActions";
import Header from "../Header";
import AboutMeCard from "../TravllerDeatils/AboutMeCard";
import { Likes } from "../atoms/Likes";
import { PassIcon } from "../atoms/PassIcon";
import AppleMap from "../template/AppleMap";
import ItenaryDetailCard from "./ItenaryDetailCard";
import OtherTypeCard from "./OtherTypeCard";

const ItenaryDetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { card, image } = location.state || {};

  const { tripDetails = {} } = useSelector((state) => state.auth);
  const [markers, setMarkers] = useState([]);

  const [cardData, setCardData] = useState({
    totalDays: 0,
    daysFilter: [{ key: "all", value: "All" }],
    filteredCards: {},
    allFilteredCards: [],
    selectedFilter: { key: "all", value: "All" },
    placesKeys: [],
  });

  const [center, setCenter] = useState({
    latitude: 28.4595,
    longitude: 77.0266,
  });

  const [zoom, setZoom] = useState({ latitudeSpan: 0.05, longitudeSpan: 0.05 });

  const handleSelectMarker = (marker) => {
    console.log("Selected marker:", marker);
  };

  const handleProfileClick = () => {
    if (card.associatedUsers?.length && card.associatedUsers[0]?.id) {
      navigate(`/traveller-detail/${card.associatedUsers[0]?.id}`, {
        state: {
          card: {
            userId: card.associatedUsers[0]?.id,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (card && card.id) {
      dispatch(getItineraryDetails(card.id));
    }
  }, [card?.id]);

  useEffect(() => {
    let arrMarkers = [];
    if (Object.keys(tripDetails).length) {
      let totalDays = tripDetails?.trip?.length;
      let daysFilter = [{ key: "all", value: "All" }];
      let placesKeys = [];

      // plannedDay,plannedDate
      for (let i = 0; i < totalDays; i++) {
        if (tripDetails?.trip[i].plannedDay)
          daysFilter.push({
            key: "day",
            value: `${i}`,
          });
        else if (tripDetails?.trip[i].plannedDate)
          daysFilter.push({
            key: "date",
            value: `${tripDetails?.trip[i].plannedDate}`,
          });
        else if (
          !tripDetails?.trip[i].plannedDate ||
          !tripDetails?.trip[i].plannedDay
        )
          daysFilter.push({
            key: "",
            value: "NYP",
          });

        let trips = tripDetails?.trip[i].items?.length;

        for (let j = 0; j < trips; j++) {
          if (
            tripDetails?.trip[i].items[j].latitude &&
            tripDetails?.trip[i].items[j].longitude
          ) {
            let obj = {
              latitude: parseFloat(tripDetails?.trip[i].items[j].latitude),
              longitude: parseFloat(tripDetails?.trip[i].items[j].longitude),
              type: tripDetails?.trip[i].items[j]?.type,
              placeType: tripDetails?.trip[i].items[j]?.place_type,
              costRange:
                tripDetails?.trip[i].items[j]?.cost_range_per_person_in_inr,
              rating: tripDetails?.trip[i].items[j]?.rating,
              totalRatings: tripDetails?.trip[i].items[j]?.total_ratings,
              title:
                tripDetails?.trip[i].items[j]?.startAddress ??
                tripDetails?.trip[i].items[j]?.name,
              image: getStoredImages(
                tripDetails?.trip[i].items[j]?.photoReference
              ),
            };

            arrMarkers.push(obj);
          }
        }
      }

      setCenter((prev) => ({
        ...prev,
        latitude: parseFloat(arrMarkers[0]?.latitude),
        longitude: parseFloat(arrMarkers[0]?.longitude),
      }));

      setMarkers(arrMarkers);
      setCardData((prev) => ({
        ...prev,
        totalDays,
        daysFilter,
        placesKeys,
        selectedFilter: { key: "all", value: "All" },
      }));
      handleSelection({ key: "all", value: "All" });
    }
  }, [tripDetails]);

  const chipStyle = {
    backgroundColor: "#F1F1F1",
    fontFamily: "Mulish",
    fontWeight: "700",
    fontSize: "14px",
    color: "#666666",
    padding: "3px 10px 2px 10px",
    borderRadius: "12px",
  };

  const mobileChipStyle = {
    ...chipStyle,
    fontSize: "10px",
    padding: "0px",
  };

  const viewOnlyChipStyle = {
    ...chipStyle,
    color: "#EAAE33",
    backgroundColor: "#FDF7EB",
  };

  const viewOnlyChipStyleMobile = {
    ...mobileChipStyle,
    color: "#EAAE33",
    backgroundColor: "#FDF7EB",
  };

  const handleSelection = (filter) => {
    if (filter.key === "all") {
      setCardData((prev) => ({
        ...prev,
        filteredCards: {},
        selectedFilter: filter,
        allFilteredCards: tripDetails?.trip,
      }));
    } else {
      let dayTrips = [];
      if (filter?.key === "day") {
        dayTrips = tripDetails?.trip.filter(
          (trip) => filter.value === trip?.plannedDay
        )[0];
      } else {
        dayTrips = tripDetails?.trip.filter(
          (trip) => filter.value === trip?.plannedDate
        )[0];
      }

      setCardData((prev) => ({
        ...prev,
        filteredCards: dayTrips,
        selectedFilter: filter,
      }));
    }
  };

  // cards UI
  const renderCards = (day, items = []) => {
    const handleCardClick = (card) => {
      console.log(card, "mkjcmwekj");
      navigate(`/destination-detail/${card?.startPlace}/${card?.id}`, {
        state: { card: { place_id: card?.startPlace } },
      });
    };

    return (
      <div style={{ marginTop: "15px" }}>
        <Typography
          color="#333333"
          fontSize={"18px"}
          fontFamily={"Mulish"}
          fontWeight={"700"}
          padding={"15px 0px"}
        >
          {/* {day ? `Day ${day}` : "Not Yet Planned"} */}
          {day?.plannedDate ? (
            formatDate(day?.plannedDate)
          ) : day?.plannedDay ? (
            `Day ${day?.plannedDay}`
          ) : (
            <Stack gap="12px">
              {/* <Box>Not yet planned</Box> */}
              <Box sx={{ marginRight: "3px" }}>Nothing's here!</Box>
            </Stack>
          )}
        </Typography>

        {items?.map((item, index) => {
          // Determine which card to render based on the type

          if (item.type === "PLACE") {
            return (
              <ItenaryDetailCard
                key={index}
                trip={item}
                image={getStoredImages(item?.photoReference)}
                onClick={() => handleCardClick(item)}
              />
            );
          } else {
            return (
              <OtherTypeCard
                key={index}
                trip={item}
                image={getStoredImages(item?.photoReference)}
                // onClick={() => handleCardClick(item)}
              />
            );
          }
        })}
      </div>
    );
  };

  const startDateDay = formatDate(tripDetails?.start, "ncjkac");
  const getEndDate = () => {
    const date = formatDate(tripDetails?.end, "ncfkjsdcfk");
    return `${date?.day} ${date?.month} ${date?.year}`;
  };

  useEffect(() => {
    let obj = {
      name: "itinerary Detail Page",
      path: location.path,
    };
    logPageView(obj);
  }, [location]);

  return (
    <>
      <Helmet>
        {" "}
        <title>Escapekar: Trip Planner</title>
        <meta name="title" content="Escapekar: Trip Planner" />
        <meta
          name="description"
          content="Plan your perfect trip with our ultimate itinerary guide. Discover hidden gems, explore best destinations, and get personalized recommendations for a memorable travel experience. Use our AI-powered trip planner to craft your ideal travel itinerary."
        />
        <meta
          name="keywords"
          content="travel itinerary, trip planner, AI trip planning, personalized travel itinerary, best destinations, hidden gems, travel plans, trip planning AI, travel planning, secret places"
        />
        <meta name="url" content="https://escapekar.com/" />
        <meta
          name="keywords"
          content="hidden gems, best hidden gems, secret place, Diveagar Beach Maharashtra, Kollukumalai, Mandvi Gujarat, trip planner AI, AI travel, travel itinerary, travel planning, trip planner, AI trip planner, trip plans, artificial intelligence trip planning"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://escapekar.com/" />
        <meta
          property="og:title"
          content="Trip Planner - Your Ultimate Travel Guide"
        />
        <meta
          property="og:description"
          content="Discover your next adventure with Escapekar, the ultimate trip planner. Effortlessly create and customize your travel itinerary, explore destinations, and get personalized recommendations to make every journey unforgettable."
        />
        <meta property="og:site_name" content="Escapekar: Trip Planner" />
        <meta property="og:locale" content="en-IN" />
        <meta property="og:country-name" content="in" />
        <script id="data" type="application/json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "TravelItinerary",
            name: "Ultimate Travel Itinerary",
            alternateName: "Escapekar",
            description:
              "Plan your perfect trip with our ultimate itinerary guide. Discover hidden gems, explore best destinations, and get personalized recommendations.",
            url: "https://escapekar.com/",
            contactPoint: {
              "@type": "ContactPoint",
              // telephone: "+91 9599819940",
              // contactType: "customer service",
              // contactOption: "TollFree",
              areaServed: "IN",
              availableLanguage: "en",
            },
            sameAs: [
              "https://www.facebook.com/escapekar/",
              "https://www.instagram.com/escapekar/",
              "https://www.linkedin.com/company/escapekar/",
            ],
          })}
        </script>
      </Helmet>
      <Header />
      <div className="itenary-detail-main " style={{ marginBottom: "20px" }}>
        {!card ? (
          <div>No card data available</div>
        ) : (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            {/* Conditional rendering based on viewport width */}
            <div className="backgroundImgaeItenairy">
              <div
                style={{
                  backgroundImage: `url(${image?.config?.url || PopularTrips})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
                className="itenary-detail-bgImg" // Class for mobile-only styles
              >
                <div
                  style={{
                    padding: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <PassIcon icon={BackIcon} onClick={() => navigate(-1)} />

                  {/* <PassIcon icon={MapOpenIcon} onClick={()=} /> */}
                </div>

                {/* For desktop */}
                <div
                  className="itenary-detail-bgImg-details"
                  style={{ zIndex: 2 }}
                >
                  <div>
                    {/* chips */}
                    <div style={{ display: "flex", gap: "7px" }}>
                      <Chip
                        label={`${
                          tripDetails?.start
                            ? `${startDateDay?.day} - ${getEndDate()}`
                            : `${tripDetails?.days || 0} days`
                        }`}
                        style={chipStyle}
                      />
                      <Chip
                        icon={<img src={UserChip} alt="user chip" />}
                        label="Friends"
                        style={chipStyle}
                      />
                      <Chip
                        icon={<img src={EyeChip} alt="Eye chip" />}
                        label="View Only"
                        style={viewOnlyChipStyle} // Apply the custom style here
                      />
                    </div>
                    {/* title */}
                    <Typography
                      color="#FFFFFF"
                      fontSize={"32px"}
                      fontFamily={"Mulish"}
                      fontWeight={"700"}
                    >
                      {card.name}
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          cursor: "pointer",
                          alignItems: "center",
                          gap: "10px",
                        }}
                        onClick={handleProfileClick}
                      >
                        <img
                          src={getImagefromBase64(
                            tripDetails?.associatedUsers?.[0]?.profileImgBase64
                          )}
                          alt="img"
                          style={{
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                          }}
                        />
                        <Typography
                          color="#CCCCCC"
                          fontSize={"14px"}
                          fontFamily={"Mulish"}
                          fontWeight={"bold"}
                        >
                          {tripDetails?.associatedUsers?.[0]?.name}
                        </Typography>
                      </div>
                      <div>
                        <Likes likesCount={card?.likes} positionAbs={"no"} />
                      </div>
                    </div>
                  </div>
                </div>
                {/* For mobile */}
                <div className="itenary-detail-bgImg-details-mobile">
                  {/* title */}
                  <Typography
                    color="#000000"
                    fontSize={"18px"}
                    fontFamily={"Mulish"}
                    fontWeight={"700"}
                  >
                    {card.name}
                  </Typography>

                  <div style={{ display: "flex", gap: "7px" }}>
                    <Chip
                      label={`${
                        tripDetails?.start
                          ? `${startDateDay?.day} - ${getEndDate()}`
                          : `${tripDetails?.days || 0} days`
                      }`}
                      style={chipStyle}
                    />
                    <Chip
                      icon={<img src={UserChip} alt="user chip" />}
                      label="Friends"
                      style={mobileChipStyle}
                    />
                    <Chip
                      icon={<img src={EyeChip} alt="Eye chip" />}
                      label="View Only"
                      style={viewOnlyChipStyleMobile} // Apply the custom style here
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        alignItems: "center",
                        gap: "10px",
                      }}
                      onClick={handleProfileClick}
                    >
                      <img
                        src={getImagefromBase64(
                          tripDetails?.associatedUsers?.[0]?.profileImgBase64
                        )}
                        alt="img"
                        style={{
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                        }}
                      />
                      <Typography
                        color="#CCCCCC"
                        fontSize={"14px"}
                        fontFamily={"Mulish"}
                        fontWeight={"bold"}
                      >
                        {tripDetails?.associatedUsers?.[0]?.name}
                      </Typography>
                    </div>
                    <div>
                      <Likes likesCount={card?.likes} positionAbs={"no"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="width-responsive">
              {/* Overview */}
              {tripDetails?.description && (
                <div style={{ marginBottom: "20px" }}>
                  <AboutMeCard
                    about={tripDetails?.description}
                    heading={"Overview"}
                  />
                </div>
              )}

              {/* Days Filter */}
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  overflowX: "auto", // Enable horizontal scrolling
                  WebkitOverflowScrolling: "touch", // Smooth scrolling for iOS
                }}
              >
                {cardData?.daysFilter?.map((filter, index) => {
                  let date = {};
                  if (filter.key === "date") {
                    date = formatDate(filter.value, "md");
                  }
                  return (
                    <div
                      key={index}
                      className={`itenary-days-filter-selection ${
                        cardData.selectedFilter.value === filter.value
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => handleSelection(filter)}
                    >
                      {filter?.key === "day" ? (
                        <>
                          {filter.key !== "all" && (
                            <Typography
                              fontFamily={"Mulish"}
                              fontWeight={"500"}
                              fontSize={"10px"}
                            >
                              Day
                            </Typography>
                          )}
                          <Typography
                            fontFamily={"Mulish"}
                            fontWeight={"700"}
                            fontSize={"16px"}
                          >
                            {filter?.value}
                          </Typography>
                        </>
                      ) : (
                        <>
                          {filter?.key === "all" || filter?.key === "" ? (
                            <Typography
                              fontFamily={"Mulish"}
                              fontWeight={"700"}
                              fontSize={"16px"}
                            >
                              {filter?.value}
                            </Typography>
                          ) : (
                            <Stack alignItems={"center"}>
                              <Typography
                                fontFamily={"Mulish"}
                                fontWeight={"500"}
                                fontSize={"10px"}
                              >
                                {date.dayOfWeek}
                              </Typography>
                              <Typography
                                fontFamily={"Mulish"}
                                fontWeight={"700"}
                                fontSize={"14px"}
                              >
                                {date.day}
                              </Typography>
                              <Typography
                                fontFamily={"Mulish"}
                                fontWeight={"500"}
                                fontSize={"10px"}
                              >
                                {date.digitMonth}
                              </Typography>
                            </Stack>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
              </div>

              {/* Render ItenaryDetailCard based on selected filter */}
              {/* for Attraction and Palace */}
              {cardData.selectedFilter.key === "all" ? (
                <>
                  {cardData?.allFilteredCards?.length > 0 &&
                    cardData?.allFilteredCards?.map((item) =>
                      renderCards(item, item.items)
                    )}
                </>
              ) : (
                <>
                  {Object?.keys(cardData)?.length > 0 &&
                    renderCards(
                      cardData?.filteredCards,
                      cardData?.filteredCards?.items
                    )}
                </>
              )}
            </div>

            <div className="sticky-map">
              <AppleMap
                markers={markers}
                setMarkers={setMarkers}
                onSelectMarker={handleSelectMarker}
                center={center}
                zoom={zoom}
              />
            </div>
            {/* for every device */}
          </div>
        )}
      </div>
    </>
  );
};

export default ItenaryDetail;
